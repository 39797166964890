import Modernizr from "../vendor/custom-modernizr";
import $ from "jquery";

// Object Fit Fallback
class ObjectFitPoly {

    constructor(dataAttr = "data-object-fit") {
        this.dataAttr = dataAttr;
    }

    fill() {

        const isSupported = window.Modernizr.objectfit;

        if (!isSupported) {
            $(`[${this.dataAttr}]`).each((i, el) => {
                const $img = $(el);
                const imgSrc = $img.attr("src");
                const fitType = $img.attr(`${this.dataAttr}`);

                $img.wrap(`<div class='object-fit-wrapper' style='background: transparent url("${imgSrc}") no-repeat center center/ ${fitType};'></div>`);

                $img.css({
                    opacity: 0
                });
            });
        }

    }

}

export { ObjectFitPoly };
