/**
 * Injects an SVG sprite into the page to allow usage of SVG maps.
 * @param {string} path - Path to sprite file.
 * @param {Object} el - DOM element to inject sprite into.
 * @returns {undefined}
 */
class SpriteLoader {
    constructor(path, el) {
        this.path = path;
        this.el = el;
    }

    append() {
        const ajax = new XMLHttpRequest();

        ajax.open("GET", this.path, true);
        ajax.send();

        ajax.onload = () => {

            const div = document.createElement("div");

            div.style.display = "none";
            div.innerHTML = ajax.responseText;
            this.el.appendChild(div);
        };
    }
}

export default SpriteLoader;








