/**
 * Global functions are loaded here
 */

import SpriteLoader from "../modules/svg-sprite-loader";
import $ from "jquery";
import { ObjectFitPoly } from "../polyfills/object-fit";

const mashbo = {

    init() {

        const elements = {
            body: document.body,
            navBtnOpen: document.getElementById("NavOpenButton"),
            navBtnClose: document.getElementById("NavCloseButton"),
            mainNav: document.getElementById("MainNav")
        };

        // Append SVG Sprite
        // no-undef disabled as wpUrls is undefined until injected by Wordpress. SM
        // eslint-disable-next-line no-undef
        const sprite = new SpriteLoader(`${wpUrls.themeFolder}/assets/images/sprite.svg `, elements.body);

        sprite.append();

        // Nav Toggle
        elements.navBtnOpen.addEventListener("click", () => {
            elements.mainNav.classList.add("main-nav--open");
        });
        elements.navBtnClose.addEventListener("click", () => {
            elements.mainNav.classList.remove("main-nav--open");
        });

        // Append SVG Sprite
        // no-undef disabled as wpUrls is undefined until injected by Wordpress. SM
        // eslint-disable-next-line no-undef
        const objectFit = new ObjectFitPoly("data-object-fit");

        objectFit.fill();


    }
};

mashbo.init();
